$white: #ffffff;
$secondaryWhite: #f9f4f4;
$whiteSmoke: #f5f5f5;
$darkerSmoke: #F0F0F0;
$black: #000000;
$blackGrey: #2E2D31;
$blackGrey1: #232225;
$blackGrey2: #2d2d2d;
$blackGrey3: #2B2B2B;
$blackGrey4: #2E2E2E;
$blackGrey5: #5C5C5C;
$blackT10: rgba($black, 0.1);
$blackT20: rgba($black, 0.2);
$blackT25: rgba($black, 0.25);
$blackT30: rgba($black, 0.3);
$blackT40: rgba($black, 0.4);
$blackT50: rgba($black, 0.5);
$whiteT0: rgba($white, 0);
$whiteT50: rgba($white, 0.5);

$primaryGrey: #383838;
$primaryGrey2: #333333;
$secondaryGrey: #757171;

$tertiaryGrey: #C5C5C5;
$backgroundGrey: #FDFDFD;
$backgroundGrey2: #FAFAFA;
$backgroundGrey3: #F7F7F7;
$archivedBackgroundGrey: #E3E3E3;
$borderGrey: #ECE9E9;
$borderGrey2: #303030;
$borderGrey3: #E5E7EB;
$orangeLightBorder: #FDE5D8;
$orangeLightBackground: #fff5f0;
$blueLightBorder: #DAE3FA;
$tagsBackground: #E0E0E0;
$yellow: #FFF847;
$yellow1: #FFCC33;
$yellow2: #f3c886;
$goldenCaramel: #956C0E;

$violetFlame: #6204BF;
$blue: #1f52bb;
$blue2: #163b88;
$blue3: #F2F5FD;
$blue4: #F3F6FC;
$blue5: #183f90;
$blue6: #1d4cab; // HSV 220°, 83%, 67%

$blueLight: #DAE3FA;
$blueLight2: #E5ECFB;
$blueLight3: #F3F6FC;
$blueLight4: #DCE5FA;
$blueLight5: #F0F4FD;
$blueLight6: #EBEFFA;
$blueLight7: #CDDAFA;
$blueHover: #EDF1FD;
$blueHover1: #F6F8FE;
$orange: #F36D2A;
$orangeRed: #FBD8D0;
$orange1: #FFE4D6;
$orange2: #D96125;
$orange3: #F26D28;
$orange4: #ff9900;
$orange5: #F58852;
$orangeLight: #FFF2EB;
$orangeLight1: #FFE0CC;
$orangeLight2: #FCF1EB;
$orangeLight3: #FDE8DD;
$orangeLight4: #F9C3A9;
$orangeLight5: #FFF8F5;

$red: #8e021a;
$red-01: #bf0000;
$redLight: #e8ccd1;
$red-light-01: #fadad5;
$red-cancelled: #ffe6e6;
$red-error: #D92B2F;
$light-pink: #EEE0FF;

$purple: #660099;
$red-cancelled-dark: #FF0000;

$green: #38701c;
$green1: #009900;
$greenLight: #ebf1e8;

$grey2: #ebebeb;
$grey3: #3a3a3a;
$grey4: rgba(117, 113, 113, 1);
$grey5: #323232;
$gray6: #dddddd; // #dddddd
$grey7: #6f6f6f;
$grey8: #d9d9d9;
$grey9: #f7f7f7;
$grey10: #CBCBCB;
$grey11: #E8E8E8;
$grey12: #666666;
$grey13: #4B4B4B;
$grey14: #949494;
$grey15: #262626;
$grey16: #212121;
$grey17: #D6D6D6;
$grey18: #F2F2F2;
$grey19: #5C5C5C;

$grey15T50: rgba($grey15, 0.5);
$blueChip: #0067c5;
$blueBadge: #03658C;
$overlayBackground: rgba($black, 0.4);

$hoverBoxShadow: 0px 4px 4px rgba(0, 103, 197, 0.3);
$activeBoxShadow: inset 0px 4px 4px rgba($black, 0.25);
$activeIconBoxShadow: inset 0px 4px 4px rgba(0, 103, 197, 0.25);
$elevateBoxShadow: 0px 4px 4px $blackT10;
$btnBoxShadowHover: 0px 4px 4px $blackT40;
$btnBoxShadowActive: 0px 4px 4px $blackT40 inset;
$hoverCueTvBoxShadow: 0px 4px 4px rgba(142, 2, 26, 0.4);

$boxShadow: 10px 0px 8px -6px $blackT30;
$boxShadow1: 13px 0px 8px -6px $blackT30;
$boxShadow3: 0px 0px 20px 0px $blackT25;

$facebookBackground: linear-gradient(#19afff, #0062e0);
$darkModeBackgroundGradient: linear-gradient(270deg, $grey16 10.28%, rgba(33, 33, 33, 0.5) 52.39%);
$instagramBackground: radial-gradient(
  circle at 30% 107%,
  #fdf497 0%,
  #fdf497 5%,
  #fd5949 45%,
  #d6249f 60%,
  #285aeb 90%
);
$twitterBackground: rgba(29, 161, 242, 1);
$instagramBackground: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
$twitterBackground: rgba(29, 161, 242, 1.00);
$youtubeBackground: rgb(255, 0, 0);
$linkedinBackground: rgb(0, 119, 181);

$blur: blur(3px);